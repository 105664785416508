import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  styled, TextField, Button, Paper,
} from '@mui/material';
import ErrorMessage from './modais/ErrorMessage';
import useAxiosWithTimeout from './utils/AxiosWithTimeout';

const FormContainer = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  marginTop: '50px',
  width: '300px',
});

const LogoContainer = styled(Paper)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '150px',
  backgroundImage: `url(${process.env.PUBLIC_URL}/logo_blanca.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
});

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // define o useHistory para redirecionar o usuário
  const { makeRequest } = useAxiosWithTimeout();

  const submitLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await makeRequest({
        url: '/api/token',
        data: { username, password },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
      });

      if (response.status === 200 && response.data) {
        const accessToken = response.data;
        if (accessToken) {
          // Armazena o token
          localStorage.setItem('token', response.data.access_token);
          // Se o token for verdadeiro, redireciona para a tela inicial
          navigate('/inicio');
        }
      }
    } catch (erro) {
      setMensagemErro(`Erro ao realizar login: ${erro}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <LogoContainer />
      <form onSubmit={submitLogin}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormContainer>
            <TextField
              label="Usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              label="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="normal"
              required
            />
            <ErrorMessage message={mensagemErro} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Entrando...' : 'Entrar'}
            </Button>
          </FormContainer>
        </div>
      </form>
    </div>
  );
}
