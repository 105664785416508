import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ErrorMessage from '../modais/ErrorMessage';
import { API_V1_PREFIX } from '../../App';
import useAxiosWithTimeout from '../utils/AxiosWithTimeout';

function obterQuantidadeDeDiasRestantesNaSemana() {
  // Lógica para calcular a data do próximo domingo com base nos dias restantes
  const today = new Date();
  const diasRestantes = 7 - today.getDay(); // Dias restantes até o próximo domingo
  if (diasRestantes === 7) {
    return 6;
  }
  return diasRestantes;
}

export default function ProximasLocacaoes() {
  const [isLoadingBusca, setIsLoadingBusca] = useState(false);
  const [locacaoEncontrado, setLocacaoEncontrado] = useState(undefined);
  const [mensagemErro, setMensagemErro] = useState('');
  const [locacoes, setLocacoes] = useState([]);
  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment().add(obterQuantidadeDeDiasRestantesNaSemana(), 'days').format('YYYY-MM-DD'));
  const { makeRequest } = useAxiosWithTimeout();

  async function buscarLocacoes() {
    setIsLoadingBusca(true);

    try {
      const response = await makeRequest({
        url: `${API_V1_PREFIX}/locacoes_completas?data_inicial=${dataInicial}&data_final=${dataFinal}`,
        method: 'GET',
      });
      if (response.status === 200 && response.data) {
        setLocacaoEncontrado(true);
        setLocacoes(response.data);
      }
    } catch (erro) {
      setMensagemErro(erro);
      setLocacaoEncontrado(false);
    } finally {
      setIsLoadingBusca(false);
    }
  }

  function formatarData(dataISO) {
    const data = new Date(dataISO);
    data.setHours(data.getHours() + data.getTimezoneOffset() / 60);
    // Ajuste a hora para considerar a diferença de fuso horário
    const dia = (`0${data.getDate()}`).slice(-2);
    const mes = (`0${data.getMonth() + 1}`).slice(-2);
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  useEffect(() => {
    buscarLocacoes();
  }, [dataInicial, dataFinal]);

  return (
    <div>
      {isLoadingBusca ? (
        <CircularProgress
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : locacaoEncontrado ? (
        <TableContainer component={Paper}>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <Typography variant="h6" style={{ marginTop: '10px' }}>
              Locações dos proximos dias:
            </Typography>
            <TextField
                name="data_inicio"
                label="Data Inicial"
                type="date"
                value={dataInicial}
                onChange={(e) => setDataInicial(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginLeft: '10px' }}
                required
            />
            <TextField
                name="data_final"
                label="Data Final"
                type="date"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginLeft: '10px' }}
                required
            />
          </div>
          <Table
            aria-label="Tabela de Próximas Locações"
            style={{ marginTop: '10px' }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="right">Cliente</TableCell>
                <TableCell align="right">Dama</TableCell>
                <TableCell align="right">Idade</TableCell>
                <TableCell align="right">Data Retirada</TableCell>
                <TableCell align="right">Data Evento</TableCell>
                <TableCell align="right">Valor Restante</TableCell>
                <TableCell align="right">Observações</TableCell>
                <TableCell align="right">Codigos Trajes</TableCell>
                <TableCell align="right">Descricao Trajes</TableCell>
                <TableCell align="right">Tamanhos</TableCell>
                <TableCell align="right">Codigos Acessorios</TableCell>
                <TableCell align="right">Descricao Acessorios</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locacoes.map((locacao) => (
                <TableRow key={`locacao-${locacao.id}`}>
                  <TableCell align="right">
                    {locacao.nome_cliente}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.nome_dama}
                  </TableCell>
                  <TableCell align="right">{locacao.idade}</TableCell>
                  <TableCell align="right">
                    {formatarData(locacao.data_retirada)}
                  </TableCell>
                  <TableCell align="right">
                    {formatarData(locacao.data_evento)}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.valor_restante}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.observacoes}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.trajes_locados.map(
                      (traje) => traje.codigo_traje,
                    ).join('; ')}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.trajes_locados.map(
                      (traje) => traje.descricao,
                    ).join('; ')}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.trajes_locados.map(
                      (traje) => traje.tamanho,
                    ).join('; ')}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.acessorios_locados.map(
                      (acessorio) => acessorio.codigo_acessorio,
                    ).join('; ')}
                  </TableCell>
                  <TableCell align="right">
                    {locacao.acessorios_locados.map(
                      (acessorio) => acessorio.descricao,
                    ).join('; ')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          <ErrorMessage message={mensagemErro} />
        </div>
      )}
    </div>
  );
}
