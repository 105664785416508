import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();

  function handleLogout() {
    // remove o token
    localStorage.removeItem('token');
    // realizar o logout
    navigate('/login');
  }

  return (
    <header style={{ marginLeft: '20px' }}>
      <nav>
        <button onClick={handleLogout}>Logout</button>
      </nav>
    </header>
  );
}
