import React, { useState, useRef } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tabs,
  Tab,
} from '@mui/material';
import Header from './Header';
import ConsultarLocacao from './locacao/ConsultarLocacao';
import CadastrarLocacao from './locacao/CadastrarLocacao';
import GeradorPdf from './pdf_contrato/GeradorPdf';
import CadastrarCliente from './cliente/CadastrarCliente';
import CadastrarAcessorio from './acessorio/CadastrarAcessorio';
import CadastrarTraje from './traje/CadastrarTraje';
import CadastrarUsuario from './usuario/CadastrarUsuario';
import ConsultarCliente from './cliente/ConsultarCliente';
import ConsultarAcessorio from './acessorio/ConsultarAcessorio';
import ConsultarTraje from './traje/ConsultarTraje';
import ConsultarUsuario from './usuario/ConsultarUsuario';
import Disponibilidades from './disponibilidade/Disponibilidades';
import ProximasLocacaoes from './inicio/ProximasLocacoes';

export default function Inicio() {
  const scrollRef = useRef(undefined);
  const [activeTab, setActiveTab] = useState(0);
  const [operation, setOperation] = useState('consultar');
  const [entidade, setEntidade] = useState('inicio');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // scroll para a esquerda quando uma tab é clicada (ainda sem funcionar)
    scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
  };

  const operationOptions = ['consultar', 'cadastrar'];

  return (
    <div
      style={{
        display: 'flex',
        WebkitOverflowScrolling: 'touch',
        scrollBehavior: 'smooth',
      }}
      ref={scrollRef}
    >
      <div>
        <Header />

        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            row
            aria-label="operacao"
            name="operacao"
            value={operation}
            onChange={(event) => setOperation(event.target.value)}
            style={{ marginLeft: '20px' }}
          >
            {operationOptions.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <div style={{ overflowX: 'hidden', width: '105%' }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Inicio" onClick={() => setEntidade('inicio')} />
            <Tab label="Locação" onClick={() => setEntidade('locacao')} />
            <Tab
              label="PDF Contrato"
              onClick={() => setEntidade('pdf_contrato')}
            />
            <Tab label="Cliente" onClick={() => setEntidade('cliente')} />
            <Tab
              label="Disponibilidades"
              onClick={() => setEntidade('disponibilidade')}
            />
            <Tab label="Traje" onClick={() => setEntidade('traje')} />
            <Tab label="Acessório" onClick={() => setEntidade('acessorio')} />
            <Tab label="Usuário" onClick={() => setEntidade('usuario')} />
          </Tabs>
        </div>

        <div style={{ marginLeft: '20px', width: '110%' }}>
          <div style={{ marginTop: '16px' }}>
            {entidade === 'inicio' && (
              <div>
                <ProximasLocacaoes />
              </div>
            )}

            {entidade === 'locacao' && (
              <div>
                {operation === 'consultar' ? (
                  <ConsultarLocacao />
                ) : (
                  <CadastrarLocacao />
                )}
              </div>
            )}

            {entidade === 'pdf_contrato' && (
              <div>
                <GeradorPdf />
              </div>
            )}

            {entidade === 'cliente' && (
              <div>
                {operation === 'consultar' ? (
                  <ConsultarCliente />
                ) : (
                  <CadastrarCliente />
                )}
              </div>
            )}

            {entidade === 'disponibilidade' && (
              <div>{<Disponibilidades />}</div>
            )}

            {entidade === 'traje' && (
              <div>
                {operation === 'consultar' ? (
                  <ConsultarTraje />
                ) : (
                  <CadastrarTraje />
                )}
              </div>
            )}

            {entidade === 'acessorio' && (
              <div>
                {operation === 'consultar' ? (
                  <ConsultarAcessorio />
                ) : (
                  <CadastrarAcessorio />
                )}
              </div>
            )}

            {entidade === 'usuario' && (
              <div>
                {operation === 'consultar' ? (
                  <ConsultarUsuario />
                ) : (
                  <CadastrarUsuario />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
